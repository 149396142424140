import React, { useState } from 'react';
import Tabs from '@jwp/ott-ui-react/src/components/Tabs/Tabs';
import Tab from '@jwp/ott-ui-react/src/components/Tab/Tab';

import styles from './MediaTabs.module.scss';

const MediaTabs = ({ titles, children }: { titles: string[]; children: React.ReactNode[] }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = titles.map((title, index) => ({ title, content: children[index] })).filter(({ title }) => !!title);

  return (
    <div className={styles.tabsContainer}>
      <Tabs onTabSelect={setSelectedTab} selectedTab={selectedTab}>
        {tabs.map(({ title, content }) => (
          <Tab key={title} title={title}>
            {content}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default MediaTabs;
