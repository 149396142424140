import React, { type PropsWithChildren, useRef } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import Image from '../Image/Image';
import { useScrolledDown } from '../../hooks/useScrolledDown';
import useBreakpoint, { Breakpoint } from '../../hooks/useBreakpoint';

import styles from './Hero.module.scss';

type Props = PropsWithChildren<{
  image?: string;
  className?: string;
  infoClassName?: string;
  portalTo?: HTMLElement | null;
}>;

const Hero = ({ image, children, className, infoClassName, portalTo = document.body }: Props) => {
  const alt = ''; // intentionally empty for a11y, because adjacent text alternative
  const posterRef = useRef<HTMLImageElement>(null);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint <= Breakpoint.sm;

  useScrolledDown(50, isMobile ? 150 : 500, (progress: number) => {
    if (posterRef.current) posterRef.current.style.opacity = `${Math.max(1 - progress, 0.1)}`;
  });

  // use a portal for the background to prevent overflow clipping when animating in Safari
  const portalWrapper = (heroElement: JSX.Element) => {
    if (portalTo) return createPortal(heroElement, portalTo);

    return heroElement;
  };

  return (
    <div className={classNames([styles.hero, className])}>
      {portalWrapper(
        <>
          <Image ref={posterRef} className={styles.poster} image={image} width={1280} alt={alt} />
          <div className={styles.posterFadeMenu} />
          <div className={styles.posterFadeLeft} />
          <div className={styles.posterFadeBottom} />
          <div className={styles.posterBackground} />
        </>,
      )}
      <div className={classNames(styles.info, infoClassName)}>{children}</div>
    </div>
  );
};

export default Hero;
