import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from '@jwp/ott-common/src/utils/compare';
import type { PlaylistItem, Playlist } from '@jwp/ott-common/types/playlist';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { useAccountStore } from '@jwp/ott-common/src/stores/AccountStore';
import { mediaURL } from '@jwp/ott-common/src/utils/urlFormatting';
import CardGrid from '@jwp/ott-ui-react/src/components/CardGrid/CardGrid';

const MediaTabRelated = ({ item, playlist, isLoading }: { item: PlaylistItem; playlist: Playlist | undefined; isLoading: boolean }) => {
  const { t } = useTranslation('video');
  const { config, accessModel } = useConfigStore(({ config, accessModel }) => ({ config, accessModel }), shallow);
  const { user, subscription } = useAccountStore(({ user, subscription }) => ({ user, subscription }), shallow);
  const { features } = config;

  const getUrl = (item: PlaylistItem) => mediaURL({ id: item.mediaid, title: item.title, playlistId: features?.recommendationsPlaylist });
  const isLoggedIn = !!user;
  const hasSubscription = !!subscription;

  if (!playlist) {
    return null;
  }

  return (
    <CardGrid
      playlist={playlist}
      isLoading={isLoading}
      accessModel={accessModel}
      isLoggedIn={isLoggedIn}
      currentCardItem={item}
      currentCardLabel={t('current_video')}
      hasSubscription={hasSubscription}
      getUrl={getUrl}
    />
  );
};

export default MediaTabRelated;
