import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslationKey } from '@jwp/ott-hooks-react/src/useTranslationKey';
import useBreakpoint, { Breakpoint } from '@jwp/ott-ui-react/src/hooks/useBreakpoint';
import type { PlaylistItem } from '@jwp/ott-common/types/playlist';
import { countListValues, hasFormatMetadata } from '@jwp/ott-common/src/utils/metadata';
import PlayTrailer from '@jwp/ott-theme/assets/icons/play_trailer.svg?react';
import { mediaURL } from '@jwp/ott-common/src/utils/urlFormatting';
import useMovieData from '@jwp/ott-hooks-react/src/useMovieData';
import useMovieParams from '@jwp/ott-ui-react/src/hooks/useMovieParams';
import useMovieHandlers from '@jwp/ott-ui-react/src/hooks/useMovieHandlers';
import useScrollReset from '@jwp/ott-ui-react/src/hooks/useScrollReset';
import type { ScreenComponent } from '@jwp/ott-ui-react/types/screens';
import Cinema from '@jwp/ott-ui-react/src/containers/Cinema/Cinema';
import TrailerModal from '@jwp/ott-ui-react/src/containers/TrailerModal/TrailerModal';
import ShareButton from '@jwp/ott-ui-react/src/components/ShareButton/ShareButton';
import FavoriteButton from '@jwp/ott-ui-react/src/containers/FavoriteButton/FavoriteButton';
import Button from '@jwp/ott-ui-react/src/components/Button/Button';
import Icon from '@jwp/ott-ui-react/src/components/Icon/Icon';
import VideoMetaData from '@jwp/ott-ui-react/src/components/VideoMetaData/VideoMetaData';
import VideoFormats from '@jwp/ott-ui-react/src/components/VideoFormats/VideoFormats';
import VideoDefinitionList from '@jwp/ott-ui-react/src/components/VideoDefintionsList/VideoDefinitionList';
import MediaHead from '@jwp/ott-ui-react/src/components/MediaHead/MediaHead';
import MediaHeroInfo from '@jwp/ott-ui-react/src/components/MediaHeroInfo/MediaHeroInfo';
import MediaHeroButtons from '@jwp/ott-ui-react/src/components/MediaHeroButtons/MediaHeroButtons';
import MediaHero from '@jwp/ott-ui-react/src/components/MediaHero/MediaHero';
import MediaTabs from '@jwp/ott-ui-react/src/components/MediaTabs/MediaTabs';
import MarkdownComponent from '@jwp/ott-ui-react/src/components/MarkdownComponent/MarkdownComponent';
import MediaTabRelated from '@jwp/ott-ui-react/src/components/MediaTabRelated/MediaTabRelated';
import MediaTabAbout from '@jwp/ott-ui-react/src/components/MediaTabAbout/MediaTabAbout';
import StartWatchingButton from '@jwp/ott-ui-react/src/containers/StartWatchingButton/StartWatchingButton';
import env from '@jwp/ott-common/src/env';

import styles from './MediaMovieVideodock.module.scss';

const MediaMovieVideodock: ScreenComponent<PlaylistItem> = ({ data, isLoading }) => {
  const { t } = useTranslation('video');
  const translationKey = useTranslationKey('title');
  const breakpoint = useBreakpoint();
  const { id, play, feedId } = useMovieParams();
  const {
    playlist,
    nextItem,
    playUrl,
    movieURL,
    trailerItem,
    hasTrailer,
    isFavoritesEnabled,
    isEntitled,
    playTrailer,
    primaryMetadata,
    isPlaylistLoading,
    setPlayTrailer,
  } = useMovieData(data, id, feedId);
  const { handleBack, handleComplete } = useMovieHandlers(nextItem, movieURL);

  const canonicalUrl = data ? `${env.APP_PUBLIC_URL}${mediaURL({ id: data.mediaid, title: data.title })}` : window.location.href;
  const hasVideoFormats = hasFormatMetadata(data);

  const smallScreen = breakpoint < Breakpoint.md;
  const trailerButton = hasTrailer ? (
    <Button
      label={t('video:trailer')}
      aria-label={t('video:watch_trailer')}
      startIcon={<Icon icon={PlayTrailer} />}
      onClick={() => setPlayTrailer(true)}
      active={playTrailer}
      disabled={!trailerItem}
    />
  ) : undefined;

  useScrollReset(id);

  return (
    <React.Fragment>
      <MediaHead canonicalUrl={canonicalUrl} data={data} />
      <MediaHero image={data.backgroundImage}>
        <MediaHeroInfo
          title={data.title}
          description={data.synopsis as string}
          metadataComponent={<VideoMetaData attributes={primaryMetadata} />}
          formatComponent={hasVideoFormats ? <VideoFormats item={data} /> : null}
          definitionComponent={
            <VideoDefinitionList
              listProperties={[
                { label: t('video:metadata.actors', { count: countListValues(data?.actors as string) }), value: data?.actors as string },
                { label: t('video:metadata.directors', { count: countListValues(data?.directors as string) }), value: data?.directors as string },
              ]}
            />
          }
        />
        <MediaHeroButtons
          cta={
            <>
              <StartWatchingButton
                key={id} // necessary to fix autofocus on TalkBack
                item={data}
                playUrl={playUrl}
              />
              {!smallScreen && trailerButton}
            </>
          }
        >
          {smallScreen && trailerButton}
          {isFavoritesEnabled ? <FavoriteButton item={data} /> : undefined}
          <ShareButton title={data.title} description={data.description} url={canonicalUrl} />
        </MediaHeroButtons>
      </MediaHero>
      <MediaTabs titles={[(playlist?.[translationKey] as string) || playlist?.title || '', t('video:about_tab')]}>
        <MediaTabRelated playlist={playlist} item={data} isLoading={isLoading || isPlaylistLoading} />
        <MediaTabAbout description={<MarkdownComponent className={styles.markdown} markdownString={data.description} headerOffset={1} />}>
          <div className={styles.aboutDefinitionList}>
            <VideoDefinitionList
              className={styles.definitionList}
              splitByComma={false}
              listProperties={[
                { label: t('video:metadata.directors', { count: countListValues(data?.directors as string) }), value: data?.directors as string },
                { label: t('video:metadata.cast'), value: data?.cast as string },
                { label: t('video:metadata.rating'), value: data?.rating as string },
                ...(hasVideoFormats ? [{ label: t('video:metadata.formats'), value: <VideoFormats item={data} /> }] : []),
              ]}
            />
            <VideoDefinitionList
              className={styles.definitionList}
              splitByComma={false}
              listProperties={[
                { label: t('video:metadata.genres', { count: countListValues(data?.genre as string) }), value: data?.genre as string },
                { label: t('video:metadata.audio_languages'), value: data?.audioLanguages as string },
                { label: t('video:metadata.subtitle_languages'), value: data?.subtitleLanguages as string },
              ]}
            />
          </div>
        </MediaTabAbout>
      </MediaTabs>
      <Cinema
        open={play && isEntitled}
        onClose={handleBack}
        item={data}
        title={data.title}
        primaryMetadata={<VideoMetaData attributes={primaryMetadata} />}
        onComplete={handleComplete}
        feedId={feedId ?? undefined}
        onNext={handleComplete}
      />
      <TrailerModal item={trailerItem} title={`${data.title} - Trailer`} open={playTrailer} onClose={() => setPlayTrailer(false)} />
    </React.Fragment>
  );
};

export default MediaMovieVideodock;
