import { useQuery } from '@tanstack/react-query';
import type { Config } from '@jwp/ott-common/types/config';
import type { Settings } from '@jwp/ott-common/types/settings';
import { getModule } from '@jwp/ott-common/src/modules/container';
import AppController from '@jwp/ott-common/src/controllers/AppController';
import type { AppError } from '@jwp/ott-common/src/utils/error';
import { useTranslation } from 'react-i18next';

type Resources = {
  config: Config;
  configSource: string | undefined;
  settings: Settings;
};

export type OnReadyCallback = (config: Config | undefined) => void;

export const useBootstrapApp = (url: string) => {
  const applicationController = getModule(AppController);
  const { i18n } = useTranslation();

  const { data, isLoading, error, isSuccess, isFetched, refetch } = useQuery<Resources, Error | AppError>({
    queryKey: ['config-init'],
    queryFn: () => applicationController.initializeApp(url, i18n.language),
    refetchInterval: false,
    retry: 1,
  });

  return {
    data,
    isLoading,
    error,
    isSuccess,
    isFetched,
    refetch,
  };
};

export type BootstrapData = ReturnType<typeof useBootstrapApp>;
