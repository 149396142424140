import { useTranslation } from 'react-i18next';
import { type UseBaseQueryResult, useQuery } from '@tanstack/react-query';
import type { PlaylistItem } from '@jwp/ott-common/types/playlist';
import ApiService from '@jwp/ott-common/src/services/ApiService';
import { getModule } from '@jwp/ott-common/src/modules/container';
import { isScheduledOrLiveMedia } from '@jwp/ott-common/src/utils/liveEvent';
import { STALE_TIME_SHORT } from '@jwp/ott-common/src/constants';

export type UseMediaResult<TData = PlaylistItem, TError = unknown> = UseBaseQueryResult<TData, TError>;

export default function useMedia(mediaId: string, enabled: boolean = true): UseMediaResult {
  const apiService = getModule(ApiService);

  // Determine currently selected language
  const { i18n } = useTranslation();
  const language = i18n.language;

  return useQuery({
    queryKey: ['media', mediaId, language],
    queryFn: () => apiService.getMediaById({ id: mediaId, language }),
    enabled: !!mediaId && enabled,
    refetchInterval: (query) => {
      if (!query.state.data) return false;

      const autoRefetch = isScheduledOrLiveMedia(query.state.data);

      return autoRefetch ? 1000 * 30 : false;
    },
    staleTime: STALE_TIME_SHORT,
  });
}
