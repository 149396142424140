import React, { type PropsWithChildren, useEffect } from 'react';
import { IS_DEMO_MODE, IS_DEVELOPMENT_BUILD, IS_PREVIEW_MODE, IS_PROD_MODE } from '@jwp/ott-common/src/utils/common';
import ErrorPage from '@jwp/ott-ui-react/src/components/ErrorPage/ErrorPage';
import CookieBanner from '@jwp/ott-ui-react/src/components/CookieBanner/CookieBanner';
import AccountModal from '@jwp/ott-ui-react/src/containers/AccountModal/AccountModal';
import DevConfigSelector from '@jwp/ott-ui-react/src/components/DevConfigSelector/DevConfigSelector';
import LoadingOverlay from '@jwp/ott-ui-react/src/components/LoadingOverlay/LoadingOverlay';
import { type BootstrapData, useBootstrapApp } from '@jwp/ott-hooks-react/src/useBootstrapApp';
import { setThemingVariables } from '@jwp/ott-ui-react/src/utils/theming';
import { AppError } from '@jwp/ott-common/src/utils/error';
import { useUIStore } from '@jwp/ott-common/src/stores/UIStore';

import DemoConfigDialog from '../../components/DemoConfigDialog/DemoConfigDialog';
import AppRoutes from '../AppRoutes/AppRoutes';

import registerCustomScreens from '#src/screenMapping';
import { useTrackConfigKeyChange } from '#src/hooks/useTrackConfigKeyChange';

const IS_DEMO_OR_PREVIEW = IS_DEMO_MODE || IS_PREVIEW_MODE;

const BootstrapError = ({ error }: { error: Error | AppError }) => {
  if (error instanceof AppError) {
    return <ErrorPage title={error.payload.title} message={error.payload.description} helpLink={error.payload.helpLink} error={error} />;
  }
  return <ErrorPage error={error} />;
};

const ProdContentLoader = ({ children, query }: PropsWithChildren<{ query: BootstrapData }>) => {
  const { isLoading, error } = query;

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <BootstrapError error={error} />;
  }

  return children;
};

const DemoContentLoader = ({ query, children }: PropsWithChildren<{ query: BootstrapData }>) => {
  const { isLoading, isFetched, error, data } = query;

  // Show the spinner while loading except in demo mode (the demo config shows its own loading status)
  if (!IS_DEMO_OR_PREVIEW && isLoading) {
    return <LoadingOverlay />;
  }

  const { configSource } = data || {};

  return (
    <>
      {isFetched && data?.config ? children : null}
      {/* Show the error page when error except in demo mode (the demo mode shows its own error) */}
      {!IS_DEMO_OR_PREVIEW && error && <BootstrapError error={error} />}
      {IS_DEMO_OR_PREVIEW && <DemoConfigDialog query={query} />}
      {/* Config select control to improve testing experience */}
      {(IS_DEVELOPMENT_BUILD || IS_PREVIEW_MODE) && <DevConfigSelector selectedConfig={configSource} />}
    </>
  );
};

// This is moved to a separate, parallel component to reduce rerenders
const RootLoader = ({ children }: PropsWithChildren) => {
  const query = useBootstrapApp(window.location.href);

  // Modify query string to add / remove app-config id
  useTrackConfigKeyChange(query.data?.settings, query.data?.configSource);

  useEffect(() => {
    if (query.data?.config) {
      setThemingVariables(query.data.config);
    }
  }, [query.data?.config]);

  return IS_PROD_MODE ? <ProdContentLoader query={query}>{children}</ProdContentLoader> : <DemoContentLoader query={query}>{children}</DemoContentLoader>;
};

const Root = () => {
  // Register custom screen mappings
  useEffect(() => {
    registerCustomScreens();
  }, []);

  return (
    <RootLoader>
      <AppRoutes />
      <AccountModal />
      <CookieBanner onOpen={() => useUIStore.setState({ cookieWallOpen: true })} onClose={() => useUIStore.setState({ cookieWallOpen: false })} />
    </RootLoader>
  );
};

export default Root;
