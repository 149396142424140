export async function initSentry() {
  // disable Sentry in DEV
  if (!import.meta.env.APP_SENTRY_DSN || import.meta.env.DEV) {
    return;
  }

  const SentryReact = await import('@sentry/react');
  const { getSentryOptions } = await import('@jwp/ott-ui-react/src/utils/sentry');

  const options = getSentryOptions(import.meta.env.APP_SENTRY_DSN, import.meta.env.APP_SENTRY_ENVIRONMENT, import.meta.env.APP_VERSION);

  SentryReact.init(options);

  SentryReact.setTag('platform', 'web');
}
