import React, { type PropsWithChildren } from 'react';

import styles from './MediaTabAbout.module.scss';

const MediaTabAbout = ({ description, children }: PropsWithChildren<{ description: string | React.ReactNode }>) => {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.aboutDescription}>{description}</div>
      <div className={styles.aboutMetadata}>{children}</div>
    </div>
  );
};

export default MediaTabAbout;
