const redactKeys = ['email', 'password', 'username', 'address', 'country', 'ip', 'ipaddress'];

export const stripPIIData = (data: Record<string, unknown> | undefined) => {
  if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object' && data[key] !== null) {
        data[key] = stripPIIData(data[key] as Record<string, unknown>);
      } else if (redactKeys.includes(key)) {
        data[key] = '<redacted>';
      }
    });
  }
  return data;
};
