import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@jwp/ott-common/src/queryClient';

type QueryProviderProps = {
  children: JSX.Element;
};

function QueryProvider({ children }: QueryProviderProps): JSX.Element {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default QueryProvider;
