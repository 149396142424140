import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import type { Props as TabProps } from '../Tab/Tab';

import styles from './Tabs.module.scss';

type MakeArray<T> = T | T[];
type Children = MakeArray<React.ReactElement<TabProps> | null>;

type Props = {
  children: Children;
  onTabSelect: (index: number) => void;
  selectedTab: number;
  className?: string;
};

type TabButtonProps = {
  index: number;
  title: string;
  onTabSelect: () => void;
  selectedTab: boolean;
};

const TabButton = ({ selectedTab, onTabSelect, index, title }: TabButtonProps) => {
  return (
    <li key={index} role="tab" aria-controls="tab-content" aria-selected={selectedTab ? 'true' : 'false'}>
      <button onClick={onTabSelect} className={classNames(styles.tabButton, { [styles.active]: selectedTab })} data-title={title}>
        {title}
      </button>
    </li>
  );
};

const Tabs: React.FC<Props> = ({ children, selectedTab, onTabSelect, className }) => {
  const tabs = React.Children.toArray(children) as React.ReactElement<TabProps>[];
  const tabListRef = useRef<HTMLUListElement>(null);
  const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });

  useEffect(() => {
    const button = tabListRef.current?.querySelectorAll('button')[selectedTab];

    if (!button) return;

    // this assumes that the parent is rendered without extra padded containers
    const parentOffset = tabListRef.current?.getBoundingClientRect()?.left || 0;
    const buttonRect = button.getBoundingClientRect();

    setIndicatorStyle({ left: buttonRect.left - parentOffset, width: buttonRect.width });
  }, [selectedTab, children]);

  return (
    <>
      <ul className={classNames(styles.tabHeader, className)} role="tablist" ref={tabListRef}>
        {tabs.map((item, index) => (
          <TabButton selectedTab={selectedTab === index} key={item.props.title} index={index} title={item.props.title} onTabSelect={() => onTabSelect(index)} />
        ))}
        <div className={styles.indicator} aria-hidden="true" style={indicatorStyle} role="presentation" />
      </ul>
      <div id="tab-content" className={styles.tabsContent}>
        {tabs[selectedTab]}
      </div>
    </>
  );
};

export default Tabs;
