import type { BrowserOptions } from '@sentry/browser';
import { stripPIIData } from '@jwp/ott-common/src/utils/pii';

export function getSentryOptions(dsn: string, environment: string, version: string | undefined): BrowserOptions {
  return {
    dsn,
    environment,
    release: version,
    // Unhandled error is triggered by the InPlayer WebSocket retry mechanism and clutters Sentry
    ignoreErrors: ['Unhandled error. (undefined)', 'Share canceled'],
    beforeBreadcrumb(breadcrumb) {
      // prevent sending console.log messages as this may contain PII-sensitive data
      if (breadcrumb.category === 'console' && breadcrumb.level === 'log') {
        return null;
      }

      breadcrumb.data = stripPIIData(breadcrumb.data);
      return breadcrumb;
    },
    beforeSend(event) {
      if (event.user) {
        event.user = stripPIIData(event.user);
      }

      return event;
    },
  };
}
