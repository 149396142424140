import { QueryClient } from '@tanstack/query-core';

import { CACHE_TIME, STALE_TIME } from './constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      gcTime: CACHE_TIME,
      refetchOnWindowFocus: false,
      retryOnMount: false,
    },
  },
});
