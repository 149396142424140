import React from 'react';
import classNames from 'classnames';
import useBreakpoint, { Breakpoint } from '@jwp/ott-ui-react/src/hooks/useBreakpoint';

import styles from './MediaHeroButtons.module.scss';

const MediaHeroButtons = ({
  cta,
  children,
  className,
  buttonClassOverride = true,
}: {
  cta: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  buttonClassOverride?: boolean;
}) => {
  const breakpoint = useBreakpoint();
  const buttonClassName = buttonClassOverride ? (breakpoint < Breakpoint.md ? styles.rectangleButton : styles.roundButton) : undefined;

  const validChildren = React.Children.toArray(children).filter(React.isValidElement);

  return (
    <div className={classNames(styles.buttonBar, className)}>
      {cta}
      {validChildren.map((child) => React.cloneElement(child as React.ReactElement, { className: buttonClassName }))}
    </div>
  );
};

export default MediaHeroButtons;
