import { type FetchNextPageOptions, useInfiniteQuery } from '@tanstack/react-query';
import type { EpisodesWithPagination } from '@jwp/ott-common/types/series';
import type { Pagination } from '@jwp/ott-common/types/pagination';
import ApiService from '@jwp/ott-common/src/services/ApiService';
import { getModule } from '@jwp/ott-common/src/modules/container';
import { useTranslation } from 'react-i18next';

const getNextPageParam = (pagination: Pagination) => {
  const { page, page_limit, total } = pagination;

  // In case there are no more episodes in a season to fetch
  if (page_limit * page >= total) {
    return undefined;
  }

  return page;
};

export const useEpisodes = (
  seriesId: string | undefined,
  seasonNumber: string | undefined,
  options: { enabled: boolean },
): {
  data: EpisodesWithPagination[];
  hasNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  isLoading: boolean;
} => {
  const apiService = getModule(ApiService);

  // Determine currently selected language
  const { i18n } = useTranslation();
  const language = i18n.language;

  const {
    data,
    fetchNextPage,
    isLoading,
    hasNextPage = false,
  } = useInfiniteQuery({
    queryKey: [seriesId, seasonNumber, language],
    queryFn: async ({ pageParam }) => {
      if (Number(seasonNumber)) {
        // Get episodes from a selected season using pagination
        const season = await apiService.getSeasonWithEpisodes({
          seriesId,
          seasonNumber: Number(seasonNumber),
          pageOffset: pageParam,
          language,
        });

        return { pagination: season.pagination, episodes: season.episodes };
      } else {
        // Get episodes from a selected series using pagination
        return apiService.getEpisodes({ seriesId, pageOffset: pageParam, language });
      }
    },
    getNextPageParam: (lastPage) => getNextPageParam(lastPage?.pagination),
    initialPageParam: 0,
    enabled: options.enabled,
  });

  return {
    data: data?.pages || [],
    isLoading,
    fetchNextPage,
    hasNextPage,
  };
};
