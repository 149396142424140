import { useQuery } from '@tanstack/react-query';
import ApiService from '@jwp/ott-common/src/services/ApiService';
import { getModule } from '@jwp/ott-common/src/modules/container';

export const useSeriesLookup = (mediaId: string | undefined) => {
  const apiService = getModule(ApiService);

  const { isLoading, data } = useQuery({
    queryKey: ['seriesLookup', mediaId],
    queryFn: async () => {
      if (!mediaId) {
        return;
      }

      // get all series for the given media id
      const data = await apiService.getSeriesByMediaIds([mediaId]);
      // get first series for the requested episode
      return data?.[mediaId]?.[0];
    },
    enabled: !!mediaId,
  });

  return {
    isLoading,
    data,
  };
};
